import classNames from "classnames";

export const styles = {
  h2: classNames(
    "text-grey-darkest text-3xl font-bold leading-tighter",
    "lg:text-4xl"
  ),
  h3: classNames(
    "text-grey-darkest text-2xl font-bold leading-tighter",
    "lg:text-3xl"
  ),
  h4: classNames(
    "text-grey-darkest text-xl font-bold leading-tighter",
    "lg:text-2xl"
  ),
  h5: classNames(
    "text-grey-darkest text-lg font-bold leading-tighter",
    "lg:text-xl"
  ),
  button: {
    default: classNames("text-white bg-green hover:bg-green-dark"),
  },
  textInput: classNames(
    "appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight",
    "focus:outline-none focus:ring h-10"
  ),
};
