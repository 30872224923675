import Case from "case";
import { Formik } from "formik";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { BiTrash } from "react-icons/bi";
import * as Yup from "yup";
import CartContext from "../../context/cart-context";
import { Price } from "../../utilities/price";

function CartItemDetail({ product }) {
  const cartContext = useContext(CartContext);
  const price = product.salePrice ? product.salePrice : product.basePrice;

  return (
    <div
      key={product.id}
      className="flex flex-wrap justify-between p-4 items-top border border-grey-light rounded-lg mb-4"
    >
      <div className="">
        <Link to={product.url}>
          <img
            src={product.image}
            alt={product.title}
            className="w-16 h-16 object-cover block rounded"
          />
        </Link>
      </div>
      <div className="flex-1 pl-4">
        <div className="flex flex-wrap justify-between gap-2">
          <div>
            <h5 className="text-base font-medium flex-1 mt-2">
              <Link className="no-underline text-grey-darkest" to={product.url}>
                {product.title}
              </Link>
            </h5>
            {product.attributes &&
              product.attributes.map((item, index) => {
                return (
                  <p className="text-xs font-light" key={index}>
                    {Case.sentence(item.variationAttributeName)}:{" "}
                    {Case.sentence(item.variationAttributeValue)}
                  </p>
                );
              })}
          </div>

          <div className="">
            <div className="flex items-center justify-end gap-2 mb-3">
              <Formik
                initialValues={{ quantity: product.quantity }}
                onSubmit={(values, { setSubmitting }) => {
                  cartContext.setItemQuantity(product.id, values.quantity);
                  setSubmitting(false);
                }}
                validationSchema={Yup.object().shape({
                  quantity: Yup.number().required("Required"),
                })}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <input
                        type="number"
                        name="quantity"
                        value={values.quantity}
                        onChange={(e) => {
                          handleChange(e);
                          setTimeout(() => {
                            handleSubmit();
                          });
                        }}
                        onBlur={handleBlur}
                        min="1"
                        className={
                          errors.quantity && touched.quantity
                            ? "pt-1 pb-2 px-2 w-12 rounded border border-red-dark"
                            : "pt-1 pb-2 px-2 w-12 rounded border border-grey-light"
                        }
                      />
                      {errors.quantity && touched.quantity && (
                        <div className="text-red-dark">{errors.quantity}</div>
                      )}
                    </form>
                  );
                }}
              </Formik>
              <button
                aria-label="Remove from cart"
                className="border border-grey-light rounded p-2"
                onClick={() => {
                  cartContext.removeItemFromCart(product.id);
                }}
              >
                <BiTrash size={20} />
              </button>
            </div>
            <div className="text-right">
              <div>
                <Price value={price} />
              </div>
              {!!product.discount && (
                <div className={`text-${process.env.THEME_COLOR_PRIMARY}`}>
                  Discount: <Price value={product.discount} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

CartItemDetail.propTypes = {
  id: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  quantity: PropTypes.number,
  attributes: PropTypes.array,
};

export default CartItemDetail;
