const zipCodes = [
  84003, 84004, 84005, 84006, 84009, 84010, 84011, 84013, 84014, 84015, 84016,
  84020, 84025, 84037, 84040, 84041, 84042, 84043, 84044, 84045, 84047, 84054,
  84056, 84057, 84058, 84059, 84062, 84065, 84067, 84070, 84075, 84081, 84084,
  84087, 84088, 84089, 84090, 84091, 84092, 84093, 84094, 84095, 84096, 84097,
  84101, 84102, 84103, 84104, 84105, 84106, 84107, 84108, 84109, 84110, 84111,
  84112, 84113, 84114, 84115, 84116, 84117, 84118, 84119, 84120, 84121, 84122,
  84123, 84124, 84125, 84126, 84127, 84128, 84129, 84130, 84131, 84132, 84133,
  84134, 84138, 84139, 84141, 84143, 84145, 84147, 84148, 84150, 84151, 84152,
  84157, 84158, 84165, 84170, 84171, 84180, 84184, 84190, 84199, 84201, 84244,
  84315, 84401, 84402, 84403, 84404, 84405, 84408, 84409, 84412, 84414, 84415,
  84601, 84602, 84603, 84604, 84605, 84606, 84626, 84633, 84651, 84653, 84655,
  84660, 84663, 84664,
];

const extendedZipCodes = [
  84335, 84074, 84029, 84098, 84328, 84071, 84306, 84321, 84339, 84302, 84304,
  84032, 84036, 84309, 84337, 84314, 84318, 84341, 84319, 84049, 84322, 84323,
  84324, 84325, 84326, 84332, 84645, 84648, 84327, 84055, 84060, 84068, 84340,
];

export { extendedZipCodes, zipCodes };
