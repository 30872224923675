import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export const HeaderImage = () => {
  return (
    <StaticImage
      alt={process.env.COMPANY_NAME_SHORT}
      className="m-auto"
      height={150 * 0.33}
      src="../../images/wasatch-covers-logo.svg"
      width={264 * 0.33}
    />
  );
};
