import { useLocation } from "@reach/router";
import classNames from "classnames";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useContext, useEffect, useState } from "react";
import CartContext from "../../context/cart-context";
import { navItems, productNavItems } from "../../data/nav-items";
import CartIcon from "../../images/icon-cart.svg";
import { HeaderImage } from "../images/header-image";

const componentStyles = {
  Mountainland: {
    container: `text-black bg-${process.env.THEME_COLOR_PRIMARY}`,
    links: `text-black`,
    viewAllProductsButton: "text-black bg-yellow font-medium",
    viewCartButton: "bg-black text-white",
  },
  Wasatch: {
    container: `text-white bg-${process.env.THEME_COLOR_PRIMARY}-darker`,
    links: `text-white hover:text-${process.env.THEME_COLOR_PRIMARY}-light`,
    viewAllProductsButton: `text-white bg-${process.env.THEME_COLOR_PRIMARY}-darkest`,
    viewCartButton: `bg-white text-${process.env.THEME_COLOR_PRIMARY}-darkest`,
  },
};

const NavDesktop = ({ siteTitle }) => {
  const cart = useContext(CartContext);
  const [productDropdownOpen, setProductDropdownOpen] = useState(false);
  const { pathname, search } = useLocation();

  useEffect(() => {
    setProductDropdownOpen(false);
  }, [pathname, search]);

  return (
    <div className="hidden md:block relative">
      <div
        className={classNames(
          `flex w-full justify-between items-center p-4 relative z-40`,
          componentStyles[process.env.COMPANY_NAME_SHORTER].container
        )}
      >
        <Link to="/" title={siteTitle}>
          <HeaderImage />
        </Link>
        <div className="flex items-center justify-end">
          <div className="flex pr-6 justify-end tracking-wide text-sm md:text-[14px] items-center gap-8 xl:text-[16px]">
            <button
              className="hover:underline cursor-pointer"
              onClick={() => setProductDropdownOpen(!productDropdownOpen)}
            >
              Shop Products
            </button>
            {navItems.map((item, itemIndex) => (
              <div className="dropdown relative" key={itemIndex}>
                <Link
                  to={item.link}
                  className={classNames(
                    "block no-underline",
                    "hover:underline",
                    componentStyles[process.env.COMPANY_NAME_SHORTER].links
                  )}
                >
                  {item.title}
                </Link>
                {item.subNav && (
                  <div className="hidden absolute pt-2 z-20 dropdown-content w-64">
                    <div className="shadow">
                      {item.subNav.map((subItem, index) => (
                        <Link
                          className={`block py-3 px-4 text-grey-darker bg-grey-lighter hover:bg-grey-light hover:text-black no-underline`}
                          key={index}
                          to={subItem.link}
                        >
                          {subItem.title}
                        </Link>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
          <Link
            to="/cart/"
            className={classNames(
              "relative flex items-center gap-1 px-4 py-2 rounded",
              "hover:bg-gray-darkest",
              componentStyles[process.env.COMPANY_NAME_SHORTER].viewCartButton
            )}
            title="Cart"
          >
            <span>Cart</span>
            <img src={CartIcon} alt="cart" />
            {cart.contents.length > 0 && (
              <span className="header-cart-icon text-center">
                {cart.contents.length}
              </span>
            )}
          </Link>
        </div>
      </div>
      {/* Mega Nav */}
      <div
        className="absolute w-full z-20 shadow-md"
        style={{
          top: productDropdownOpen ? "100%" : "-600px",
          transition: "all .5s ease-out",
        }}
        onMouseLeave={() => setProductDropdownOpen(false)}
      >
        <div className="bg-white p-4 lg:px-12 lg:py-16">
          <div className="flex justify-between">
            <div className="w-1/2">
              <div className="grid grid-cols-3 gap-4 mb-8">
                {productNavItems.map((item, itemIndex) => (
                  <div key={itemIndex}>
                    <Link
                      to={item.link}
                      className={`block pb-2 font-bold hover:text-${process.env.THEME_COLOR_PRIMARY} no-underline`}
                    >
                      {item.title}
                    </Link>
                    {item.subNav && (
                      <div>
                        {item.subNav.map((subItem, index) => (
                          <Link
                            className={`block py-2 text-grey-darker hover:text-${process.env.THEME_COLOR_PRIMARY}-darker no-underline`}
                            key={index}
                            to={subItem.link}
                          >
                            {subItem.title}
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <Link
                to="/products/"
                className={classNames(
                  "inline-block text-black tracking-wide px-8 py-2 no-underline rounded",
                  componentStyles[process.env.COMPANY_NAME_SHORTER]
                    .viewAllProductsButton
                )}
              >
                View all products
              </Link>
            </div>
            <div className="w-1/2 grid grid-cols-2 gap-4">
              <Link to="/window-well-covers/">
                <div className="bg-white shadow-md rounded-xl overflow-hidden">
                  <StaticImage
                    className="w-full h-64 object-cover"
                    src="../../images/nav-covers.jpg"
                    alt="Window Well Covers"
                    width={500}
                    height={300}
                  />
                  <div className="p-6">
                    <h3 className="text-default text-lg md:text-xl bold mb-2 text-grey-darkest">
                      Window Well Covers
                    </h3>
                    <div
                      className={`text-${process.env.THEME_COLOR_PRIMARY}-dark`}
                    >
                      Shop now
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/window-well-liners/">
                <div className="bg-white shadow-md rounded-xl overflow-hidden">
                  <StaticImage
                    className="w-full h-64 object-cover"
                    src="../../images/nav-liners.jpg"
                    alt="Window Well Liners"
                    width={500}
                    height={300}
                  />
                  <div className="p-6">
                    <h3 className="text-default text-lg md:text-xl bold mb-2 text-grey-darkest">
                      Window Well Liners
                    </h3>
                    <div
                      className={`text-${process.env.THEME_COLOR_PRIMARY}-dark`}
                    >
                      Shop now
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NavDesktop;

// hover:bg-yellow-darker
// bg-yellow
