const counties = [
  {
    link: "/window-well-covers-for-salt-lake-county/",
    text: "Salt Lake County",
  },
  {
    link: "/window-well-covers-utah-county/",
    text: "Utah County",
  },
  {
    link: "/window-well-covers-for-davis-county/",
    text: "Davis County",
  },
  {
    link: "/window-well-covers-for-weber-county/",
    text: "Weber County",
  },
];

export default counties;
